<template>
  <div class="store">
    <div class="header">
      <div>
        <span>{{ messageList.companyName }}</span>
      </div>
      <div>
        <p>
          <span>店铺星级</span>
          <el-rate
            class="el-rate"
            v-model="value"
            disabled
            :colors="['#e2231a', '#e2231a', '#e2231a']"
          >
          </el-rate>
        </p>
        <div>
          <span @click="gocompany">
            <i class="iconfont">&#xe64a;</i>
            进店逛逛
          </span>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center-header">
        <span :class="activeIndex == 0 ? 'active' : ''" @click="activeIndex = 0"
          >店铺推荐</span
        >
        <span :class="activeIndex == 1 ? 'active' : ''" @click="activeIndex = 1"
          >热门关注</span
        >
      </div>
      <div class="center-center" v-if="itemList.length">
        <div v-if="activeIndex == 0">
          <div v-for="(v, k) of itemList" :key="k" @click="gomessage(v)">
            <img :src="v.mainImage" />
            <p v-if="v.price" v-text="`￥${v.price.toFixed(2)}`"></p>
          </div>
        </div>
        <div v-if="activeIndex == 1">
          <div>「暂无数据」</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["messageList"],
  data() {
    return {
      // 评分分数
      value: 4.4,
      //   当前选中店铺推荐
      activeIndex: 0,
      //商品推荐列表
      itemList: [],
    };
  },
  methods: {
    // 进入商家详情
    gocompany() {
      this.$router.push({
        path: "/company",
        query: {
          id: this.messageList.companyId,
        },
      });
    },
    // 查看商品详情
    gomessage(v) {
      this.$router.push({
        path: "/goodMessage",
        query: { name: JSON.stringify(v) },
      });
      window.location.reload();
    },
    //   获取店铺商品
    async getstore() {
      const res = await this.$http({
        method: "get",
        url: `front/product/findList?companyId=${this.messageList.companyId}&pageSize=10`,
      });
      if (res.data.status == 200) {
        this.itemList = res.data.data.list;
      }
    },
  },

  created() {
    this.getstore();
  },
};
</script>

<style lang="scss" scoped>
.store {
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  .header {
    & > div:nth-child(1) {
      background-color: #f7f7f7;
      border: 1px solid #eee;
      padding: 10px;
      color: #666;
      span {
        font: 700 14px "microsoft yahei";
      }
    }
    & > div:nth-child(2) {
      padding: 10px;
      border: 1px solid #eee;
      border-top: none;
      p {
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        & ::v-deep .el-rate {
          margin-left: 10px;
          display: inline-block;
          background: #e6e6e6;
          padding: 0px 5px;
          border-radius: 15px;
          height: 17px;
          .el-rate__item {
            width: 15px;
            font-size: 12px;
          }
        }
      }
      & > div {
        padding: 10px;
        padding-bottom: 0px;
        span {
          width: 70%;
          margin: 0 auto;
          cursor: pointer;
          i {
            font-size: 18px;
            color: #e3393c;
          }
          display: block;
          padding: 10px;
          border: 1px solid #eee;
          text-align: center;
          background-color: #f8f8f8;
        }
      }
    }
  }
  .center {
    border: 1px solid #eee;
    margin-top: 10px;
    .center-header {
      width: 100%;
      border-bottom: 1px solid #e4393c;
      span {
        text-align: center;
        width: 50%;
        box-sizing: border-box;
        display: inline-block;
        color: #666;
        padding: 10px 22px;
        font-size: 14px;
        font-family: "microsoft yahei";
        cursor: pointer;
        &:hover {
          color: #e4393c;
        }
      }
    }
    .center-center {
      text-align: center;
      & > div {
        div {
          width: 100%;
          margin: 15px 0px;
          cursor: pointer;
          img {
            display: block;
            margin: 0 auto;
            width: 80%;
            height: 165px;
          }
          p {
            text-align: right;
            color: #e4393c;
            font-size: 14px;
            font-family: Verdana;
            margin-right: 10px;
            padding: 8px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.active {
  background-color: #e4393c;
  color: #fff !important;
  cursor: default !important;
}
</style>