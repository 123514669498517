<template>
  <div class="detailOne">
    <div class="header" v-if="messageList">
      <div>
        <span>品牌:</span>
        <span>{{ messageList.companyName }}</span>
      </div>
      <div>
        <p>
          <span>商品名称:</span>
          <span>{{ messageList.name }}</span>
        </p>
        <p>
          <span>状态:</span>
          <span>{{ messageList.seedlingParamVo.plantState }}</span>
        </p>
        <p>
          <span>评分:</span>
          <span>{{ messageList.seedlingParamVo.grade }}</span>
        </p>
        <p>
          <span>品质:</span>
          <span>{{ messageList.seedlingParamVo.trait }}</span>
        </p>
        <p>
          <span>店铺:</span>
          <span>{{ messageList.companyName }}</span>
        </p>
      </div>
    </div>
    <div class="center" v-html="messageList.detail"></div>
  </div>
</template>

<script>
export default {
  props: ["messageList"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.detailOne {
  .header {
    padding: 40px;
    border-bottom: 1px solid #eee;
    & > div:nth-child(1) {
      margin-bottom: 25px;
    }
    & > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      p {
        width: 25%;
        margin-bottom: 10px;
      }
    }
  }
  .center {
    & ::v-deep p {
      img {
        display: block;
      }
    }
  }
}
</style>